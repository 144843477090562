import React from "react";
import PropTypes from "prop-types";
import { CheckIcon } from "@heroicons/react/outline";
import Feature from "~components/features/Feature";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";

function FeatureList({ headline, text, features }) {
	return (
		<div className="bg-pattern bg-gray-100 py-2">
			<Container>
				<Grid cols={2} className="mb-8">
					<div>
						<H2 display className="mb-5 font-display">
							{headline}
						</H2>
						<P>{text}</P>
					</div>
				</Grid>
				<Grid cols={3}>
					{features.map((feature) => (
						<Feature
							key={feature.headline}
							name={feature.headline}
							as="p"
							description={feature.text}
							icon={CheckIcon}
						/>
					))}
				</Grid>
			</Container>
		</div>
	);
}

export default FeatureList;

FeatureList.propTypes = {
	headline: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	features: PropTypes.arrayOf(
		PropTypes.shape({
			headline: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		})
	).isRequired,
};
