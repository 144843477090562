import { graphql, Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Breadcrumb from "~components/layouts/Breadcrumb";
import Button from "~components/common/Button";
import Container from "~components/common/Container";
import Divider from "~components/common/Divider";
import Grid from "~components/common/Grid";
import ProvenExpert from "~components/features/ProvenExpert";
import Layout from "~components/layouts/Layout";
import PageHeader from "~components/sections/PageHeader";
import AboPlans from "~components/sections/PricingPlans/AboPlans";
import H2 from "~components/typography/H2";
import NumberedSection from "~components/typography/NumberedSection";
import P from "~components/typography/P";
import KeySellingpoints from "~components/sections/landingpages/KeySellingpoints";
import SeoText from "~components/sections/landingpages/SeoText";
import LandingpageHeader from "~components/sections/landingpages/LandingpageHeader";
import About from "~components/sections/landingpages/About";
import FeatureList from "~components/sections/landingpages/FeatureList";
import SchemaProduct from "~components/layouts/schemas/SchemaProduct";

export default function Page({ data, location }) {
	const keyword = "Webdesign Handwerker";
	const title = "Webdesign Handwerker: Unser Full Service Angebot";
	const description = "Webdesign für Handwerker gesucht? Wir bauen professionelle Websites für Handwerksbetriebe.";

	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.headerImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.headerImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.headerImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.headerImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<SchemaProduct
				name={keyword}
				image={data.headerImage.twitterAndProduct.gatsbyImageData.images.fallback.src}
				description={description}
				aggregateOffer
				lowPrice="500"
				highPrice="5000"
				url={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
			/>
			<LandingpageHeader
				headline={{ one: `Webdesign Handwerker:`, two: "Unser Full Service Angebot" }}
				image={{ data: data.headerImage.header, alt: `${keyword} - Handwerker am Tablet` }}
				text={
					<>
						<strong>Webdesign</strong> für <strong>Handwerker</strong> gesucht? Wir wissen, dass Du keine Zeit für Deine
						Website hast und Dich gerne auf Dein Handwerk konzentrieren möchtest. Genau deswegen haben wir uns ein
						System entwickelt, bei dem wir regelmäßig um Deine Website kümmern und dafür sorgen, dass sich die Website
						stetig verbessert und Deinen Umsatz erhöht. Wir bauen professionelle und umsatzsteigernde Homepages für
						Handwerksbetriebe schon ab 60,- € im Monat.
					</>
				}
				buttonOne={{ text: "Kostenloses Beratungsgespräch vereinbaren", to: `${location.pathname}anfragen/` }}
				pages={[
					{ name: "Leistungen", to: "/leistungen/" },
					{
						name: keyword,
						to: location.pathname,
					},
				]}
			/>

			<SeoText
				headline="Der richtige Ansprechpartner mit dem richtigen Webdesign Angebot"
				intro={[
					"Wir wissen, dass Handwerksbetriebe keine Zeit für die eigene Homepage haben und sich viel lieber mit dem eigenen Handwerk beschäftigen müssen. Genau deswegen haben wir uns ein System entwickelt, bei dem wir regelmäßig um Deine Homepage kümmern und dafür sorgen, dass sich die Homepage stetig verbessert und immer erreichbar ist. Wir sehen die Homepage nicht als Produkt, sondern als Full Service. Eine Homepage ist ständig im Wandel und muss immer in einem technischen und inhaltlichen einwandfreien Zustand sein.",
				]}
				text={[
					{
						text: [
							<>
								Deswegen haben wir das{" "}
								<Link to="/leistungen/website-abo/" className="underline">
									Website-Abo
								</Link>{" "}
								entwickelt. Wir sparen uns lästige Stundenabrechnungen und Du hast immer einen festen Ansprechpartner an
								Deiner Seite.
							</>,
							"Webdesign ist unser Handwerk und deswegen sind wir auch der richtige Ansprechpartner in diesem Bereich. Es ist noch kein Webdesigner vom Himmel gefallen. Selbstgebaute Webseiten von Handwerkern erfüllen leider nicht den neusten Standard für gutes Webdesign und fallen meistens durch. Du möchtest die Besucher Deiner Webseite überzeugen, oder? Unsere Webdesigner sind handwerkliche Genies in ihrem Fach, genau wie Du Profi in Deinem Handwerk bist.",
						],
					},
					{
						text: [
							<>
								Zusätzlich erstellen wir auch die Inhalte und nehmen eine Fotoauswahl für die Homepage vor. So hast Du
								alles aus einer Hand und kannst Dir sicher sein, dass Du eine{" "}
								<span className="font-bold">bombastische Homepage</span> erhältst im neusten Webdesign.
							</>,
							"Das Vertrauen zu Deinem Kunden wird bei einer Webseite über das Webdesign entschieden. Richtiges Webdesign erfordert eine Agentur, die weiß das Responsive Design (das Anpassen der Homepage auf Handygröße) und die ständige Optimierung einer Homepage Grundvoraussetzung sind, damit Du als Handwerker Dich über glückliche Besucher aus dem Internet erfreuen kannst.",
						],
					},
				]}
			/>

			<KeySellingpoints
				sellingponts={[
					{
						headline: "Spezialist für lokale Sichtbarkeit durch Suchmaschinenoptimierung für Handwerker",
						text: [
							"Du bist neu auf dem Markt und möchtest Dich selbständig machen? Du hast schon Kunden, aber Du möchtest noch größer werden? Webdesign alleine funktioniert nicht ohne die passende Suchmaschinenoptimierung. Wir sind Experten für die Optimierung der Webseiten für Handwerksbetriebe, sodass diese bei Google gefunden werden.",
							"Willst du erfolgreich in Deiner Stadt werben, dürfen die digitalen Wege, wie eine erfolgreiche Website, nicht fehlen. Doch was ist Erfolg? Die Website ist die Eingangstür zu Deinem Unternehmen. Bevor der Kunde zu Dir kommt, besucht er Dich online. Damit Deine potenziellen Kunden Dich bei Google und Co. finden musst Du gewisse Maßnahmen treffen. In diesem Fall reicht eine schöne Website mit einem wunderschönen Webdesign nicht.",
							<>
								Mit intelligentem Design und ausgefeilter Technologie erschaffen wir Websites, die nachhaltig Deine
								Prozesse optimieren und{" "}
								<strong>
									Kunden <u>ohne</u> teure Werbeanzeigen beschaffen
								</strong>
								. Mit der SEO Premium Agentur aus Stuttgart arbeiten wir mit einem Partner zusammen, der sich auf Local{" "}
								<Link to="/leistungen/seo-agentur-kassel/" className="underline">
									SEO
								</Link>{" "}
								konzentriert. Local SEO beschreibt Marketing in Form einer Suchmaschinenoptimierung für Deine Website.
								Es werden Suchbegriffe verwendet, die besonders in Deiner Stadt gesucht werden und so wird die Webseite
								danach optimiert. So ist auch diese Seite eine Maßnahme einer solchen Optimierung.
							</>,
							"Das Handwerk leidet unter dem Fachkräftemangel? Eine ordentliche Sichtbarkeit im Netz hilft Dir auch in diesem Fall. Dein Betrieb wird bekannter und sowohl jobsuchende Handwerker, aber auch potenzielle Auszubildende, werden den Weg zu Dir finden, wenn Du es wünscht. Du siehst, dass das Internet ein großes Potenzial hat. Wir beraten Dich gerne in allen Deinen handwerklichen Herausforderungen.",
						],
						image: data.imageHandy,
						alt: `${keyword} - Kunden suchen am Handy`,
					},
					{
						headline: "Texte und Bilder für das Webdesign passend zu Deinem Handwerk",
						text: [
							<>
								Wir bieten <u>kein</u> Webdesign aus dem Baukasten. Wir sind genauso Profi in unserem Handwerk wie Du in
								Deinem. Deswegen findest Du bei Design, welches individuell auf Dich zugeschnitten ist. Wir sind Berater
								und Designer und helfen Dir Dein Unternehmen vernünftig und ehrlich im Internet zu präsentieren, sodass
								Du Dich auf Dein Handwerk konzentrieren kannst.
							</>,
							"Wir wissen das der Erfolg der Webseite stark von den Inhalten und der Gestaltung abhängt und deswegen erstellen wir bei Bedarf passend zu Deiner Branche alle wichtigen Inhalte. Du als Handwerksbetrieb musst uns nur eine Stichwortliste geben, mit Deinen Tätigkeiten und Leistungen zu senden. Eine Webseite speziell auf die Bedürfnisse der Kunden von Handwerkern zugeschnitten, hilft Deinem Handwerksbetrieb dauerhaft mit Neukunden zu versorgen.",
							"Das Webdesign einer Website hängt natürlich aber nicht nur von Texten ab. Unsere riesige Bibliothek an fertigen Stockfotos schafft es, das wir die Homepage mit diesen Bildern ausschmücken können. Solltest Du bereits Fotos haben, dann ist das umso besser! Natürlich schicken wir bei Bedarf einen Fotografen, der Deinen Handwerksbetrieb aus dem professionellsten Blickwinkel fotografiert. Du kannst diese Fotos auch gerne in Deinem Betrieb aufhängen.",
						],
						image: data.imageFotograf,
						alt: `${keyword} - Fotograf am Arbeitsplatz`,
					},
					{
						headline: "Webdesign ist nicht unser einziges Steckenpferd",
						text: [
							'Warum ist also unser Webdesign für Dich als Handwerker die richtige Lösung? Wir erstellen nicht nur eine Webseite, sondern bauen Lösungen für Dich als Handwerker. Es geht nicht nur darum, dass Besucher auf Deiner Seite landen und Dich toll finden. Wir möchten Deine Prozesse optimieren und Dir helfen lästige Tätigkeiten zu digitalisieren. Webdesign beinhaltet den Begriff "Design" und deswegen schaffen wir für Handwerker auch eine Design-Lösung. Wir möchten mit Dir als Handwerker darüber reden, was für Dich wichtig und wo wir digitale Prozesse automatisieren könnten.',
							"Unser Webdesign basiert auf der neusten JAMStack Architektur. Das ist eine Art und Weise, die andere Agenturen noch nicht bieten können. Was heißt das für Dich? Wir sind Schnittstellen-Programmierer und sehen unsere Website nicht nur aus der Webdesignsicht, sondern können Deine vorhandenen digitalen Tools aus dem Handwerk integrieren. Du bist der Experte Deines Betriebes und wir helfen Dir dabei digitale Hürden zu nehmen.",
							"Du bist selbständiger Handwerker und bist über jedes bisschen Zeit froh, die Du gewinnst. Handwerk erfordert Profis, das ist Dir bestimmt am meisten bewusst. Überlasse Dein Webdesign den Profis und wir kümmern uns.",
						],
						image: data.imageWebdesigner,
						alt: `${keyword} - Wedesigner plant eine neue Website`,
					},
				]}
			/>

			<FeatureList
				headline={`${keyword}: Unser Leistungsspektrum`}
				text={`Uns sind ganzheitliche Lösungen für unsere Kunden über aus wichtig. Daher bieten wir auch für den Bereich ${keyword} eine ganzheitliche Lösung für Deine Website. Ein einfacher Auftrag genügt und wir kümmern uns um die erfolgreiche Projektumsetzung und darüber hinaus.`}
				features={data.site.siteMetadata.globalFeautureList.webdesign}
			/>

			<About link={`${location.pathname}anfragen/`} keyword={keyword} />
		</Layout>
	);
}

export const query = graphql`
	{
		headerImage: file(relativePath: { eq: "pages/leistungen/webdesign-handwerker/webdesign-handwerker_tablet.jpg" }) {
			header: childImageSharp {
				gatsbyImageData
			}
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		imageHandy: file(relativePath: { eq: "pages/leistungen/webdesign-handwerker/webdesign-handwerker_local-seo.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageFotograf: file(
			relativePath: { eq: "pages/leistungen/webdesign-handwerker/webdesign-handwerker_fotograf.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageWebdesigner: file(
			relativePath: { eq: "pages/leistungen/webdesign-handwerker/webdesign-handwerker_webdesigner.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}

		site {
			siteMetadata {
				siteUrl
				globalFeautureList {
					webdesign {
						headline
						text
					}
				}
			}
		}
	}
`;
