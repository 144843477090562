import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

interface Props {
	className: string;
}

const ProvenExpert: React.FC<Props> = ({ className }) => {
	const data = useStaticQuery(graphql`
		{
			file(relativePath: { eq: "proven-expert.png" }) {
				childImageSharp {
					gatsbyImageData(quality: 80, width: 160)
				}
			}
		}
	`);

	const image = getImage(data.file);
	return (
		<a
			href="https://www.provenexpert.com/lautenschlager-marketing-entwicklung/"
			title="Kundenbewertungen & Erfahrungen zu Lautenschlager Marketing & Entwicklung. Mehr Infos anzeigen."
			target="_blank"
			rel="noreferrer nofollow"
			className={className ? className : undefined}
		>
			{image ? (
				<GatsbyImage
					image={image}
					alt="Erfahrungen & Bewertungen zu Lautenschlager Marketing & Entwicklung"
					title="Erfahrungen & Bewertungen zu Lautenschlager Marketing & Entwicklung"
				/>
			) : null}
		</a>
	);
};

export default ProvenExpert;
