/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import H2 from "~components/typography/H2";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";

function SeoText({ headline, intro, text }) {
	return (
		<Container className="my-8 space-y-8 md:space-y-16 lg:space-y-32">
			{headline && intro && (
				<Grid cols={2}>
					<div>
						<H2 display className="mb-5 font-display">
							{headline}
						</H2>
						{intro.map((entry, i) => {
							if (
								typeof entry === "string" ||
								(typeof entry === "object" && entry.type === Symbol.for("react.fragment"))
							) {
								return (
									<P key={`${headline}-intro-${i}`} className={`${intro.length - i - 1 <= 0 ? "" : "mb-3"}`}>
										{entry}
									</P>
								);
							}
							return <React.Fragment key={`${headline}-intro-${i}`}>{entry}</React.Fragment>;
						})}
					</div>
				</Grid>
			)}
			{text && (
				<Grid cols={2}>
					{text.map((entry) => (
						<div key={entry.headline ? entry.headline : entry.text.slice(0, 10)}>
							{entry.headline && entry.headlineAs === "h2" && (
								<H2 display className="mb-5 font-display">
									{entry.headline}
								</H2>
							)}

							{entry.headline && !entry.headlineAs && (
								<H3 as={entry.headlineAs} display className="mb-5 font-display">
									{entry.headline}
								</H3>
							)}

							{entry.text.map((entryText, i) => {
								if (
									typeof entryText === "string" ||
									(typeof entryText === "object" && entryText.type === Symbol.for("react.fragment"))
								) {
									return (
										<P
											key={`${entry.headline}-intro-${i}`}
											className={`${entry.text.length - i - 1 <= 0 ? "" : "mb-3"}`}
										>
											{entryText}
										</P>
									);
								}
								return <React.Fragment key={`${entry.headline}-intro-${i}`}>{entryText}</React.Fragment>;
							})}
						</div>
					))}
				</Grid>
			)}
		</Container>
	);
}

export default SeoText;

SeoText.propTypes = {
	headline: PropTypes.string,
	intro: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]).isRequired),
	text: PropTypes.arrayOf(
		PropTypes.shape({
			headline: PropTypes.string.isRequired,
			headlineAs: PropTypes.string,
			text: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]).isRequired),
		})
	),
};

SeoText.defaultProps = {
	headline: null,
	intro: null,
	text: null,
};
