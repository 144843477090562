import React from "react";

interface Props {
  text: string,
  number: string,
  as?: string;
  className?: string;
}

const NumberedSection: React.FC<Props> = ({ text, number, as = "div", className }) => {
  const classes = `inline-flex items-end leading-none text-base ${
    className ? className : ""
  }`
  return React.createElement(as,  { className: classes }, <NumberedSectionInnerElements text={text} number={number} />)
}

function NumberedSectionInnerElements({ text, number }: {text: string, number: string}) {
  return (
    <>
      {number && (
        <>
          {number}. <div className="w-12 mx-1 bg-black" style={{height: 1}}/>
        </>
      )}

      {text}
    </>
  )
}

export default NumberedSection