import React from "react";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";

interface Props {
	name: string;
	description: string;
	icon: JSX.Element | any;
	white?: Boolean;
	as?: string;
}

const Feature: React.FC<Props> = ({ name, icon, description, white = false, as }) => {
	const IconElement = icon;
	return (
		<div>
			<div>
				<span className="flex items-center justify-center w-8 h-8">
					<IconElement className={`h-6 w-6  ${white ? "text-white" : "text-brand"}`} aria-hidden="true" />
				</span>
			</div>
			<div className={`mt-3 ${white ? "text-white" : ""}`}>
				<H3 as={as} className="mb-1 font-display">
					{name}
				</H3>
				<P textColor="">{description}</P>
			</div>
		</div>
	);
};

export default Feature;
