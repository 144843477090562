import { CheckCircleIcon } from "@heroicons/react/outline";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Button from "~components/common/Button";
import Feature from "~components/features/Feature";
import ProvenExpert from "~components/features/ProvenExpert";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";

interface Props {
	link?: string;
	keyword?: string;
}

const About: React.FC<Props> = ({ link, keyword }) => {
	const data = useStaticQuery(graphql`
		{
			file(relativePath: { eq: "components/about/about-background-lautenschlager.jpg" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`);

	const image = getImage(data.file);

	return (
		<div className="relative bg-black-light">
			<div className="absolute inset-x-0 bottom-0 h-80 xl:top-0 xl:h-full">
				<div className="w-full h-full xl:grid xl:grid-cols-2">
					<div className="h-full xl:relative xl:col-start-2">
						{image ? (
							<GatsbyImage
								image={image}
								alt="Lautenschlager Marketing & Entwicklung - Coworking-Space"
								title="Lautenschlager Marketing & Entwicklung - Coworking-Space"
								className="object-cover w-full h-full opacity-25 xl:absolute xl:inset-0"
							/>
						) : null}
						<div
							aria-hidden="true"
							className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-black-light xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
						/>
					</div>
				</div>
			</div>
			<div className="max-w-4xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8">
				<div className="relative pt-12 pb-64 text-white sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
					<H2 as="p" display className="mb-5 font-display">
						Wer ist Lautenschlager Marketing & Entwicklung?
					</H2>
					<P textColor="">
						Wir sind Software-Entwickler und spezialisiert auf das Web und das dazugehörige Online-Marketing. Wir lieben
						unseren Job, weil der online Bereich ständig im Wandel ist und unbegrenzte Möglichkeiten bietet.
						{keyword ? ` Zudem haben wir ein System entwickelt, um die perfekte Lösung für ${keyword} anzubieten.` : ""}
					</P>
					<div className="grid grid-cols-1 mt-12 gap-y-12 gap-x-6 sm:grid-cols-2">
						<Feature
							name="Software-Entwicklung"
							description="Wir bieten echte Software-Entwicklung für Web-Technologien."
							icon={CheckCircleIcon}
							as="p"
							white
						/>
						<Feature
							name="Marketing"
							description="Wir sorgen dafür, dass Du Kunden gewinnst."
							icon={CheckCircleIcon}
							as="p"
							white
						/>

						<Feature
							name="Business-Cases"
							description="Wir bieten nachhaltige Lösungen, die Dein Business vorantreiben."
							icon={CheckCircleIcon}
							as="p"
							white
						/>

						<Feature
							name="Tools"
							description="Intelligente Breitband-Lösungen für kleine- und mittelständische Unternehmen."
							icon={CheckCircleIcon}
							as="p"
							white
						/>
						<ProvenExpert />
					</div>
					{link && <Button to={link} text={keyword ? `${keyword} anfragen` : "Anfragen"} white className="mt-6" />}
				</div>
			</div>
		</div>
	);
};

export default About;
