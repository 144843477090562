import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import PropTypes from "prop-types";
import Breadcrumb from "~components/layouts/Breadcrumb";
import Button from "~components/common/Button";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import P from "~components/typography/P";
import H2 from "~components/typography/H2";
import ReviewSlider from "~components/features/ReviewSlider";

function LandingpageHeader({ pages, image, headline, text, buttonOne, buttonTwo }) {
	return (
		<>
			<Breadcrumb pages={pages} className="mb-8" />

			<Container noPadding className="relative mb-8">
				<div className="bg-pattern p-3">
					<div className="relative sm:overflow-hidden">
						<div className="absolute inset-0">
							<GatsbyImage
								image={getImage(image.data)}
								className="h-full w-full object-cover"
								alt={image.alt}
								title={image.alt}
							/>

							<div className="absolute inset-0 bg-gradient-to-r from-brand-dark to-brand mix-blend-multiply" />
						</div>
						<div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
							<h1 className="text-center font-display text-4xl font-bold sm:text-5xl lg:text-6xl">
								<span className="block text-white">{headline.one}</span>
								{headline.two && (
									<span className="block text-2xl text-gray-100 sm:text-3xl lg:text-4xl"> {headline.two}</span>
								)}
							</h1>
							<p className="mx-auto mt-6 max-w-lg text-center text-xl text-gray-100 sm:max-w-3xl">{text}</p>
							<div className="mx-auto mt-10 flex justify-center space-x-3">
								<Button
									text={buttonOne.text}
									to={buttonOne.to}
									onClick={buttonOne.onClick}
									anchor={buttonOne.anchor}
									white
								/>
								{buttonTwo && (
									<Button
										text={buttonTwo.text}
										to={buttonTwo.to}
										onClick={buttonTwo.onClick}
										anchor={buttonTwo.anchor}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</Container>

			<Container noPadding>
				<Grid cols={2} className="mb-16" itemsCenter>
					<div>
						<H2 display className="mb-5 font-display" as="p">
							Nachweislich erfolgreiche Umsetzung
						</H2>
						<P>
							Die richtige Agentur ist entscheidend für die erfolgreiche Projektentwicklung. Du suchst unkomplizierte
							Prozesse und Kommunikation auf Augenhöhe? Wir ermöglichen eine reibungslose Planung und Entwicklung und
							nehmen Dich stets auf dem Weg zum Ziel mit.
						</P>
					</div>
					<div className="md:mt-8">
						<ReviewSlider className="" />
					</div>
				</Grid>
			</Container>
		</>
	);
}

export default LandingpageHeader;

LandingpageHeader.propTypes = {
	pages: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			to: PropTypes.string.isRequired,
		})
	).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	image: PropTypes.object.isRequired,
	headline: PropTypes.shape({
		one: PropTypes.string.isRequired,
		two: PropTypes.string,
	}).isRequired,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	buttonOne: PropTypes.shape({
		text: PropTypes.string.isRequired,
		to: PropTypes.string,
		onClick: PropTypes.func,
		anchor: PropTypes.string,
	}).isRequired,
	buttonTwo: PropTypes.shape({
		text: PropTypes.string.isRequired,
		to: PropTypes.string,
		onClick: PropTypes.func,
		anchor: PropTypes.string,
	}),
};

LandingpageHeader.defaultProps = {
	buttonTwo: null,
};
