/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import Button from "~components/common/Button";

function KeySellingpoints({ headline, intro, sellingponts = null }) {
	return (
		<Container className="my-8 space-y-8 md:space-y-16 lg:space-y-32">
			{headline && intro && (
				<Grid cols={2}>
					<div>
						<H2 display className="mb-5 font-display">
							{headline}
						</H2>

						{intro instanceof Array ? (
							intro.map((entry, j) => {
								if (
									typeof entry === "string" ||
									(typeof entry === "object" && entry.type === Symbol.for("react.fragment"))
								) {
									return (
										<P key={`${headline}-intro-${j}`} className={`${intro.length - j - 1 <= 0 ? "" : "mb-3"}`}>
											{entry}
										</P>
									);
								}
								return <React.Fragment key={`${headline}-intro-${j}`}>{entry}</React.Fragment>;
							})
						) : (
							<P>{intro}</P>
						)}
					</div>
				</Grid>
			)}
			{sellingponts.map((sellingpoint, i) => (
				<Grid cols={2} key={sellingpoint.headline} itemsStart>
					<div className={`${i % 2 === 1 ? "md:order-2" : ""}`}>
						<H2 display className="mb-5 font-display">
							{sellingpoint.headline}
						</H2>

						{sellingpoint.text.map((entry, j) => {
							if (
								typeof entry === "string" ||
								(typeof entry === "object" && entry.type === Symbol.for("react.fragment"))
							) {
								return (
									<P
										key={`${sellingpoint.headline}-intro-${j}`}
										className={`${entry.length - j - 1 <= 0 ? "" : "mb-3"}`}
									>
										{entry}
									</P>
								);
							}
							return <React.Fragment key={`${sellingpoint.headline}-intro-${j}`}>{entry}</React.Fragment>;
						})}

						{sellingpoint.button && (
							<Button
								text={sellingpoint.button.text}
								to={sellingpoint.button.to}
								onClick={sellingpoint.button.onClick}
								anchor={sellingpoint.button.anchor}
								className="mt-5"
							/>
						)}
					</div>
					<div className={`bg-pattern p-3 ${i % 2 === 1 ? "md:order-1" : ""}`}>
						<GatsbyImage
							image={getImage(sellingpoint.image)}
							alt={sellingpoint.alt}
							title={sellingpoint.alt}
							placeholder="blurred"
						/>
					</div>
				</Grid>
			))}
		</Container>
	);
}

export default KeySellingpoints;

KeySellingpoints.propTypes = {
	headline: PropTypes.string,
	intro: PropTypes.string,
	sellingponts: PropTypes.arrayOf(
		PropTypes.shape({
			headline: PropTypes.string.isRequired,
			text: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
			// eslint-disable-next-line react/forbid-prop-types
			image: PropTypes.object.isRequired,
			alt: PropTypes.string.isRequired,
			button: PropTypes.shape({
				text: PropTypes.string.isRequired,
				to: PropTypes.string,
				onClick: PropTypes.func,
				anchor: PropTypes.string,
			}).isRequired,
		})
	).isRequired,
};

KeySellingpoints.defaultProps = {
	headline: null,
	intro: null,
};
